'use client';

import styles from './footer.module.scss';
import MoshLogo from '../../MoshLogo/MoshLogo';
import MoshLogoLink from '../../MoshLogoLink/MoshLogoLink';
import SocialMediaList from '../../SocialMediaList/SocialMediaList';
import FooterNavigation from './FooterNavigation';
import Copyright from '../../Copyright/Copyright';
import { Waypoint } from 'react-waypoint';

const Footer = () => {
    const handleFooterEntering = (direction?: string) => {
        document.body.setAttribute(
            'data-footer-entered',
            direction === 'in' ? 'true' : 'false',
        );
    };
    return (
        <footer className={`${styles['footer']} px-4 py-12`}>
            <Waypoint
                onEnter={() => handleFooterEntering('in')}
                onLeave={() => handleFooterEntering('out')}
            >
                <div className="container flex flex-wrap lg:flex-nowrap">
                    <div className="mb-4 w-full md:mb-0 lg:w-1/2">
                        <MoshLogo variant="white" />
                        <MoshLogoLink />
                        <div className="hidden lg:block">
                            <SocialMediaList />
                            <Copyright />
                        </div>
                    </div>
                    <div className="flex w-full flex-wrap lg:w-1/2">
                        <FooterNavigation />
                    </div>
                    <div className="lg:hidden">
                        <SocialMediaList />
                        <Copyright />
                    </div>
                </div>
            </Waypoint>
        </footer>
    );
};

export default Footer;
