import { ReactNode } from "react";

type PageProps = {
    className?: string;
    children: ReactNode;
};

export default function Page (props: PageProps) {
    return <main className={props.className}>{props.children}</main>;
}
