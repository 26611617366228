import styles from '../Theme/Footer/footer.module.scss';
import HTMLReactParser from 'html-react-parser';

const data = {
    label: '&copy; Mosh',
    fineprint:
        'If you are interested in a prescription product, Mosh will organise an online video consultation for you with an independent doctor or nurse practitioner who will evaluate your suitability for the treatment and, if appropriate, write a prescription which you can process at a pharmacy of your choice.',
};

export default function Copyright() {
    const currentYear: number = new Date().getFullYear();

    return (
        <div className={styles['copyright']}>
            <span>
                {HTMLReactParser(data.label)} {currentYear}
            </span>
            <p className="lg:max-w-[85%]">{data.fineprint}</p>
        </div>
    );
}
