import classNames from 'classnames';
import styles from './heading.module.scss';

export type HeadingProps = {
    level?: HeadingLevel;
    tag?: HeadingTag;
    children: React.ReactNode;
    centered?: boolean;
    alternative?: boolean;
    className?: string;
    contained?: boolean;
    id?: string;
    headerStyle?: React.CSSProperties;
};

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined;
type HeadingTag = 'p' | 'span' | 'h' | undefined;

const RenderHeadingTag = ({
    tag,
    level,
    id,
    children,
    headerStyle,
}: HeadingProps) => {
    let HeadingTag;
    switch (tag) {
        case 'h':
            HeadingTag = level;
            break;
        case undefined:
            HeadingTag = 'p';
            break;
        default:
            HeadingTag = tag;
            break;
    }
    const Tag = `${HeadingTag || 'p'}` as keyof JSX.IntrinsicElements;
    return (
        <Tag
            className={classNames(styles['item'], styles[level || 'h3'])}
            id={id}
            style={headerStyle}
        >
            <>{children}</>
        </Tag>
    );
};

const Heading = (props: HeadingProps) => {
    return (
        <div
            className={classNames(
                'heading',
                styles['wrapper'],
                props.centered && styles['centered'],
                props.alternative && styles['alternative'],
                props.className,
                props.contained && styles['contained'],
            )}
            data-heading
        >
            {RenderHeadingTag(props)}
        </div>
    );
};

export default Heading;
