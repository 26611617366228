import React from 'react';
import Page from '../Page/Page';
import Footer from '../Theme/Footer/Footer';
import Header from '../Theme/Header/Header';

type LayoutHomeProps = {
    className?: string;
    children: React.ReactNode;
};

const LayoutHome = ({
    className,
    children,
}: LayoutHomeProps) => {
    return (
        <>
            <Header />
            <Page className={className}>{children}</Page>
            <Footer />
        </>
    );
};

export default LayoutHome;
