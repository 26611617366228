import styles from './card.module.scss';
import Image from 'next/image';
import Heading from 'src/components/Heading/Heading';
import { Card as CardType } from 'src/types/Card';
import classNames from 'classnames';

const Card = (props: CardType) => {
    return (
        <article
            className={classNames(
                'card',
                styles['default'],
                'h-full',
                props.className
            )}
            data-card={props.type}
        >
            <header>
                {props.image ? (
                    <Image
                        src={props.image?.src}
                        width={props.image?.width}
                        height={props.image?.height}
                        alt={props.image?.alt}
                    />
                ) : props.beforeAfterImages ? (
                    <>
                        {props.beforeAfterImages?.map((image, index) => {
                            return (
                                <Image
                                    key={index}
                                    src={image.src}
                                    width={image.width}
                                    height={image.height}
                                    alt={image.alt}
                                />
                            );
                        })}
                    </>
                ) : (
                    ''
                )}
            </header>
            <section>
                {props.specialty && (
                    <span className={`${styles['specialty']}`}>
                        {props.specialty}
                    </span>
                )}
                <div className={styles['card-title']}>
                    <Heading level={'h3'} alternative={true}>
                        {props.name}
                        {props.degree && `, ${props.degree}`}
                    </Heading>
                </div>
                {props.subtitle && (
                    <span className={styles['card-subtitle']}>
                        {props.subtitle}
                    </span>
                )}
                {props.children && (
                    <div className={styles['content']}>{props.children}</div>
                )}
            </section>
        </article>
    );
};

export default Card;
