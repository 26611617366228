import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faInstagram,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import styles from './social-media-list.module.scss';

const list = [
    {
        label: 'Facebook',
        icon: faFacebookF,
        altText: 'Follow us on Facebook',
        url: 'https://www.facebook.com/getmosh/',
    },
    {
        label: 'Instagram',
        icon: faInstagram,
        altText: 'Follow us on Instagram',
        url: 'https://www.instagram.com/get.mosh/',
    },
    {
        label: 'Tiktok',
        icon: faTiktok,
        altText: 'Follow us on Tiktok',
        url: 'https://www.tiktok.com/@get.mosh',
    },
] as const;

export default function SocialMediaList() {
    return (
        <div className={styles['social-media-list']}>
            {list.map((item, index) => (
                <a
                    className={styles['links']}
                    key={index}
                    title={item.altText}
                    href={item.url}
                    target="_blank"
                >
                    <FontAwesomeIcon icon={item.icon} />
                </a>
            ))}
        </div>
    );
}
